<template>

  <!-- Management Team -->
  <div id="story-mode-management-team">

    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">

      <!-- Content -->
      <div
        class="content"
        :class="{ 'position-relative': statusOfCompleted }"
      >
        <v-app>
          <!-- Header -->
          <header class="page-header">

            <!-- Dashboard Header -->
            <dashboard-header
              :class="{ 'stamp': statusOfCompleted }"
              :title="$t('steps.setUp.items.managementTeam')"
              :dashboard-items="dashboardManagementTeam"
              :guide-visible="guideVisible"
              :guide-visible-first-time="guideVisibleFirstTime"
              :completed-step-name="'managementTeamCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="openInfo"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />
            <!-- /Dashboard Header -->

            <!-- Divider -->
            <hr>
            <!-- /Divider -->

          </header>
          <!-- /Header -->

          <!-- Info Guide Expand Panel -->
          <info-guide
            v-if="guideVisibleFirstTime"
            class="mb-5"
            type="managementTeamExamples"
            visible
            @click="guideVisibleFirstTime = !guideVisibleFirstTime"
          />
          <!-- Info Guide Expand Panel -->

          <!-- Ai Buddy Drawer -->
          <ai-buddy-drawer
            :visible="aiBuddyDrawerVisible"
            section-name="ManagementTeam"
            help-me-write
            type="managementTeam"
            @close="aiBuddyDrawerVisible = false"
          />
          <!-- /Ai Buddy Drawer -->
        </v-app>

        <!-- Business plan additional info -->
        <business-plan-additional-info
          completed-step-name="managementTeamCompleted"
          section-name="ManagementTeam"
          show-ai-buddy
          @ai-buddy-open="aiBuddyDrawerVisible = true"
        />
        <!-- /Business plan additional info -->

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Management -->
        <div class="management">
          <!-- Header -->
          <business-guide-header>
            <template #left>
              <!-- Title -->
              <h2>
                {{ $t('pages.businessGuide.managementTeam.keyTeamMembers') }}
              </h2>
              <!-- /Title -->
              <!-- Description -->
              <p class="text-lead">
                {{ $t('pages.businessGuide.managementTeam.defineManagementTeam') }}
              </p>
              <!-- /Description -->
            </template>
            <template #right>
              <erase-icon-button
                v-if="removeButtonVisible"
                class="mr-2"
                @click="onEraseFromTemplate"
              />
              <!-- View Wrapper -->
              <view-switcher
                :active-view="$store.state.itemsView.managementTeamView"
                @activate-view="onActivateView"
              />
              <!-- /View Wrapper -->
            </template>
          </business-guide-header>
          <!-- /Header -->
        </div>

        <!-- Management Team -->
        <div class="management-team filled">

          <!-- Grid Wrapper -->
          <div class="grid-wrapper" :class="{ 'active': $store.state.itemsView.managementTeamView === 'grid' }">
            <el-row :gutter="20">
              <!-- Completed Overlay -->
              <completed-overlay
                v-if="!canEdit || $store.state.idea.storyMode.common.completedSteps.managementTeamCompleted"
                @click="checkManagement"
              />
              <!-- /Completed Overlay -->

              <div v-if="isManagementTeamEmpty" class="d-flex flex-wrap">
                <!-- Add New Member -->
                <el-col :md="8" :xl="6" class="d-flex width-lg-20 mb-4 mb-md-0">
                  <add-new-card
                    class="width-xl-20"
                    :title="$tc('addNewMember')"
                    :disabled="!canEdit || $store.state.idea.storyMode.common.completedSteps.managementTeamCompleted"
                    @click="onAddMember"
                  />
                </el-col>
                <!-- /Add New Member -->

                <!-- Placeholder 1 Blue -->
                <el-col v-if="$breakpoint.mdAndUp" :md="8" :xl="6" class="width-lg-20 mb-4 mb-md-0">
                  <div class="placeholder-card large">
                    <div>
                      <div class="avatar-placeholder blue" />
                    </div>
                  </div>
                </el-col>
                <!-- /Placeholder 1 Blue -->

                <!-- Placeholder 2 Yellow & Tutorial Tooltip -->
                <el-col v-if="$breakpoint.mdAndUp" :md="8" :xl="6" class="width-lg-20 mb-4 mb-md-0">
                  <div class="placeholder-card large">
                    <div>
                      <!-- Placeholder 2 -->
                      <div class="avatar-placeholder yellow" />
                      <!-- /Placeholder 2 -->
                    </div>
                    <!-- Tutorial Tooltip TODO - Currently disabled in if directive -->
                    <tutorial-tooltip
                      v-if="$store.state.user.tutorial.mainBottomBarCompleted === false && 1 === 2"
                      :title="$tc('addNewMember')"
                      :text="$t('pages.businessGuide.thisIsStarOfYourShow')"
                      :tutorial-name="'managementTeamCompleted'"
                    />
                    <!-- Tutorial Tooltip -->
                  </div>
                </el-col>
                <!-- /Placeholder 2 Yellow & Tutorial Tooltip -->
              </div>

              <!--Management Team Members -->
              <div v-if="!isManagementTeamEmpty" @click="checkManagement">
                <ib-draggable
                  class="d-flex flex-wrap"
                  :list="managementTeamToShowGrid"
                  :options="{disabled: hasAccess}"
                  @change="onChangeManagementTeamOrder"
                  @start="onStartManagementTeamOrder"
                >
                  <template #header>
                    <el-col :md="8" :xl="6" class="d-flex mb-4 width-lg-20">
                      <add-new-card
                        class="width-xl-20"
                        :title="$tc('addNewMember')"
                        :disabled="!canEdit || $store.state.idea.storyMode.common.completedSteps.managementTeamCompleted"
                        @click="onAddMember"
                      />
                    </el-col>
                  </template>
                  <el-col
                    v-for="member in managementTeamToShowGrid"
                    :key="member.id"
                    class="d-flex draggable mb-4 width-lg-20"
                    :md="8"
                    :xl="6"
                  >
                    <management-team-card
                      ref="managementTeamCard"
                      class="w-100"
                      :member="member"
                      @edit="onEditTeamMember"
                      @copy="onCopyTeamMember"
                      @delete="onDeleteTeamMember"
                    />
                  </el-col>
                </ib-draggable>
              </div>
              <!--/Management Team Members -->
            </el-row>
            <!-- /Grid Wrapper -->

            <!-- Show All -->
            <el-row v-if="$store.state.idea.storyMode.setUp.managementTeam.members.length > 4">
              <el-col>
                <show-all-toggle
                  :show="showAllManagementTeamGrid"
                  @click="showAllManagementTeamGrid = !showAllManagementTeamGrid"
                />
              </el-col>
            </el-row>
            <!-- /Show All -->
          </div>
          <!-- /Grid Wrapper -->

          <!-- List Wrapper -->
          <div class="list-wrapper" :class="{ 'active': $store.state.itemsView.managementTeamView === 'list' }">
            <el-row :gutter="20" class="d-flex flex-wrap">
              <!-- Completed Overlay -->
              <completed-overlay
                v-if="!canEdit || $store.state.idea.storyMode.common.completedSteps.managementTeamCompleted"
                @click="checkManagement"
              />
              <!-- /Completed Overlay -->
              <!-- Add Team Member Button -->
              <el-col v-if="isManagementTeamEmpty" :md="12" class="">
                <add-new-card
                  class="width-xl-20 py-2"
                  :title="$tc('addNewMember')"
                  horizontal
                  :disabled="!canEdit || $store.state.idea.storyMode.common.completedSteps.managementTeamCompleted"
                  @click="onAddMember"
                />
              </el-col>
              <!-- /Add Team Member Button -->

              <!-- List Members -->
              <div v-if="!isManagementTeamEmpty" class="w-100" @click="checkManagement">
                <ib-draggable
                  class="d-flex flex-wrap"
                  :list="managementTeamToShowList"
                  :options="{disabled: hasAccess}"
                  @change="onChangeManagementTeamOrder"
                  @start="onStartManagementTeamOrder"
                >
                  <template #header>
                    <el-col :md="12" class="d-flex mb-2">
                      <add-new-card
                        class="py-2 py-md-0"
                        :title="$tc('addNewMember')"
                        horizontal
                        :disabled="!canEdit || $store.state.idea.storyMode.common.completedSteps.managementTeamCompleted"
                        @click="onAddMember"
                      />
                    </el-col>
                  </template>
                  <el-col
                    v-for="member in managementTeamToShowList"
                    :key="member.id"
                    :md="12"
                    class="d-flex draggable mb-2"
                  >
                    <management-team-card
                      ref="managementTeamCard"
                      class="w-100"
                      :member="member"
                      view="list"
                      @edit="onEditTeamMember"
                      @copy="onCopyTeamMember"
                      @delete="onDeleteTeamMember"
                    />
                  </el-col>
                </ib-draggable>
              </div>
              <!-- /List Members -->
            </el-row>
            <!-- Show All -->
            <el-row v-if="$store.state.idea.storyMode.setUp.managementTeam.members.length > 7">
              <el-col>
                <show-all-toggle
                  :show="showAllManagementTeamList"
                  @click="showAllManagementTeamList = !showAllManagementTeamList"
                />
              </el-col>
            </el-row>
            <!-- /Show All -->
          </div>
          <!-- /List Wrapper -->

        </div>
        <!-- /Management Team -->

      </div>
      <!-- Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'setUp'"
        :step="'managementTeam'"
        :concept-name="'managementTeamMoreDetails'"
        :completed-step-name="'managementTeamCompleted'"
      />
      <!-- /Add More Details -->

    </div>
    <!-- Container -->

    <!-- Main Bottom Bar -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :completed-step-name="'managementTeamCompleted'"
      :step-filled="$store.state.idea.storyMode.setUp.managementTeam.members.length > 0"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="managementTeamExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Dialog Add New Member -->
    <dialog-add-team-member
      :dialog-add-team-member-visible="dialogAddTeamMemberVisible"
      :member-action="memberAction"
      @close-dialog-add-team-member="onCloseDialogAddTeamMember"
    />
    <!-- /Dialog Add New Member -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->

  </div>
  <!-- /Management Team -->

</template>

<script>
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AddNewCard from '@/components/_v2/AddNewCard'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import BusinessPlanAdditionalInfo from '@/views/Home/StoryMode/Components/BusinessPlanAdditionalInfo.vue'
import CompletedOverlay from '@/views/Home/StoryMode/Components/CompletedOverlay'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogAddTeamMember from './Dialogs/DialogAddTeamMember'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import ManagementTeamCard from '@/views/Home/StoryMode/Components/Cards/ManagementTeamCard'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import ShowAllToggle from '@/views/Home/StoryMode/Components/ShowAllToggle'
import TutorialTooltip from '@/views/Home/Components/TutorialTooltip'
import ViewSwitcher from '@/components/_v2/ViewSwitcher'

import { mapActions, mapGetters } from 'vuex'
import InfoGuide from '@/views/Home/StoryMode/Components/Example/InfoGuide.vue'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'ManagementTeam',

  components: {
    InfoGuide,
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessPlanAdditionalInfo,
    AddNewCard,
    AddMoreDetails,
    BusinessGuideHeader,
    CompletedOverlay,
    DashboardHeader,
    DialogAddTeamMember,
    DialogProcessCompleted,
    DialogRemoveExamples,
    EraseIconButton,
    MainBottomBar,
    MainNavigation,
    ManagementTeamCard,
    ShowAllToggle,
    TutorialTooltip,
    ViewSwitcher
  },

  mixins: [
    MixinProcessCompleted,
    MixinGuide,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinEraseTemplate
  ],

  data () {
    return {
      aiBuddyDrawerVisible: false,
      dialogRemoveExamplesVisible: false,
      removeExamplesLoading: false,
      dialogAddTeamMemberVisible: false,
      guideVisible: false,
      memberAction: null,
      showAllManagementTeamGrid: false,
      showAllManagementTeamList: false,
      dialogProcessCompletedVisible: false,
      dashboardManagementTeam: {
        title: 'Management Team'
      }
    }
  },

  computed: {
    ...mapGetters('idea', {
      managementTeam: 'getManagementTeam',
      getStepIsCompleted: 'getStepIsCompleted',
      getHasItemsFromTemplate: 'getHasItemsFromTemplate'
    }),

    hasAccess () {
      return !this.canEdit || this.statusOfCompleted
    },

    statusOfCompleted () {
      return this.getStepIsCompleted('managementTeamCompleted')
    },

    isManagementTeamEmpty () {
      return !this.managementTeam.length
    },

    managementTeamToShowGrid () {
      return this.showAllManagementTeamGrid === true
        ? this.managementTeam
        : this.managementTeam.slice(0, 4)
    },

    managementTeamToShowList () {
      return this.showAllManagementTeamList === true
        ? this.managementTeam
        : this.managementTeam.slice(0, 7)
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getManagementTeam')
    },

    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.financing'),
        route: 'business-guide-set-up-financing'
      }
    },

    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.staffAndPayroll'),
        route: 'business-guide-set-up-staff-and-payroll'
      }
    }
  },

  created () {
    this.openGuide('managementTeamGuide')
    this.getManagementTeam()
  },

  methods: {
    ...mapActions('idea', [
      'getManagementTeam',
      'deleteStoryModeTeamMember',
      'deleteIdeaExamples',
      'updateOrderNumber'
    ]),

    onStartManagementTeamOrder () {
      this.$refs.managementTeamCard.forEach(card => {
        card.$refs.actionsDropdown.$refs.itemDropdown.visible = false
      })
    },

    onChangeManagementTeamOrder (data) {
      this.updateOrderNumber({
        type: 'managementTeam',
        data,
        oldOrderData: this.managementTeam,
        mutation: 'setManagementTeam'
      })
    },

    checkManagement () {
      if (!this.canEdit) return
      if (this.statusOfCompleted) {
        this.dialogProcessCompletedVisible = true
      }
    },

    onAddMember () {
      if (!this.canEdit) return
      this.dialogAddTeamMemberVisible = true
    },

    onCloseDialogAddTeamMember () {
      this.dialogAddTeamMemberVisible = false
      this.memberAction = null

      // Workaround to update computed property productsToShowGrid & productsToShowList
      this.updateManagementTeamView()
    },

    updateManagementTeamView () {
      this.showAllManagementTeamList = !this.showAllManagementTeamList
      this.showAllManagementTeamList = !this.showAllManagementTeamList
      this.showAllManagementTeamGrid = !this.showAllManagementTeamGrid
      this.showAllManagementTeamGrid = !this.showAllManagementTeamGrid
    },

    onActivateView (view) {
      this.$store.commit('itemsView/setManagementTeamView', view)
    },

    onEditTeamMember (member) {
      this.memberAction = Object.assign({}, member)
      this.dialogAddTeamMemberVisible = true
    },

    onCopyTeamMember (member) {
      this.memberAction = Object.assign({}, member)
      this.memberAction.id = 0
      this.dialogAddTeamMemberVisible = true
    },

    onDeleteTeamMember (member) {
      this.deleteStoryModeTeamMember(member.id)

      // Return to grid view, if there are no products
      if (this.$store.state.idea.storyMode.setUp.managementTeam.members.length === 0) {
        this.onActivateView('grid')
      }
    },

    cardImage (img) {
      if (img) {
        return 'background-image: url(' + img + ')'
      } else {
        return ''
      }
    },

    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    },

    onEraseFromTemplate () {
      if (this.statusOfCompleted) {
        this.openedDialogProcessCompleted()

        return
      }
      this.dialogRemoveExamplesVisible = true
    },

    deleteExamples () {
      this.removeExamplesLoading = true
      this.deleteIdeaExamples('management-team')
        .then(response => {
          const { type, ids } = response
          this.deleteFromState({ type, ids })
          this.dialogRemoveExamplesVisible = false
          this.removeExamplesLoading = false
        }).catch(_ => { this.removeExamplesLoading = false })
    }
  }
}
</script>

<style scoped>
  #story-mode-management-team {
    margin-left: 0;
  }
</style>
