<template>
  <!-- Dialog Add Management Team Member -->
  <ib-dialog
    id="dialog-story-mode-add-team-member"
    empty-header
    :visible="dialogAddTeamMemberVisible"
    fullscreen
    @open="openDialog"
    @close="closeDialog"
    @closed="closedDialog"
  >
    <!-- Mobile title -->
    <template #title>
      <ib-dialog-container class="d-md-none">
        <h2>
          {{ $tc('addManagementTeamMember') }}
        </h2>
      </ib-dialog-container>
    </template>
    <!-- /Mobile title -->

    <!-- Content Wrapper -->
    <ib-dialog-container class="content-wrapper px-1" max-width="1920px">
      <!-- Header -->
      <add-dialog-header>
        <template #left>
          <!-- Title -->
          <h2 class="m-0">
            {{ $tc('addManagementTeamMember') }}
          </h2>
          <!-- /Title -->
        </template>
      </add-dialog-header>
      <!-- /Header -->

      <!-- Info Guide -->
      <info-guide-add-dialog
        class="mb-5 d-block d-xl-none"
        :visible="guideVisible"
        :title="$t('pages.businessGuide.managementTeam.addDialog.guide.title')"
        :text="$t('pages.businessGuide.managementTeam.addDialog.guide.text')"
        @click="onToggleDialogGuide"
      />
      <!-- Info Guide -->

      <!-- Inner Wrapper -->
      <div class="inner-wrapper">
        <div class="grid-wrapper">

          <el-row :gutter="20">

            <!-- Left Side -->
            <el-col :md="12" :xl="7">

              <!-- Form Wrapper -->
              <div class="member-form-wrapper">

                <!-- Form Wrapper -->
                <div class="team-member-image-uploader">

                  <!-- Trashcan Icon -->
                  <span v-show="form.imageAvatarUrl" class="icon-trash" @click="deleteImage">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" d="M15 4h4a1 1 0 110 2h-1v13a3 3 0 01-3 3H5a3 3 0 01-3-3V6H1a1 1 0 110-2h4V3a3 3 0 013-3h4a3 3 0 013 3v1zm-2 0V3a1 1 0 00-1-1H8a1 1 0 00-1 1v1h6zm3 2H4v13a1 1 0 001 1h10a1 1 0 001-1V6zm-9 4a1 1 0 112 0v6a1 1 0 11-2 0v-6zm4 0a1 1 0 112 0v6a1 1 0 11-2 0v-6z"/></svg>
                  </span>
                  <!-- /Trashcan Icon -->

                  <!-- Upload Element -->
                  <el-upload
                    class="avatar-uploader"
                    action="/api/v1/upload"
                    :show-file-list="false"
                    :before-upload="beforeAvatarUpload"
                    :on-success="handleAvatarSuccess"
                  >
                    <!-- Avatar Image -->
                    <div
                      v-if="form.imageAvatarUrl"
                      style="width: 100%; height: 200px;"
                      @click="deleteImage"
                    >
                      <img :src="form.imageAvatarUrlPreview" class="avatar">
                    </div>
                    <!-- /Avatar Image -->

                    <!-- Plus Icon -->
                    <i v-show="!form.imageAvatarUrl" class="el-icon-plus avatar-uploader-icon" />
                    <!-- /Plus Icon -->

                  </el-upload>
                  <!-- /Upload Element -->

                </div>
                <!-- /Form Wrapper -->

                <!-- Form Inner -->
                <div class="member-form-inner">

                  <!-- Form -->
                  <el-form ref="form" :model="form">

                    <!-- Team Member Name -->
                    <ib-input
                      v-model="form.name"
                      :label="$t('pages.businessGuide.managementTeam.addDialog.fields.teamMemberName')"
                      :maxlength="50"
                    />
                    <!-- /Team Member Name -->

                    <!-- Team Member Position -->
                    <ib-input
                      v-model="form.position"
                      :label="$t('pages.businessGuide.managementTeam.addDialog.fields.positionInCompany')"
                      :maxlength="50"
                    />
                    <!-- /Team Member Position -->

                    <!-- Team Member Biography -->
                    <el-form-item>
                      <el-input
                        v-model="form.description"
                        type="textarea"
                        :rows="4"
                        class=""
                        maxlength="500"
                        :placeholder="$t('pages.businessGuide.managementTeam.addDialog.fields.biography')"
                        resize="none"
                      />
                    </el-form-item>
                    <!-- /Team Member Biography -->

                  </el-form>
                  <!-- /Form -->

                </div>
                <!-- /Form Inner -->

              </div>
              <!-- /Form Wrapper -->

              <!-- Dialog Bottom -->
              <div class="my-4 my-md-0">

                <!-- Divider -->
                <ib-divider block class="my-4" />
                <!-- /Divider -->

                <!-- Discard & Add -->
                <el-row :gutter="10" class="d-flex pb-1">
                  <el-col :span="12">
                    <!-- Close -->
                    <ib-button class="w-100" variant="white" size="lg" font-family="regular" @click="closeDialog">
                      {{ $t('close') }}
                    </ib-button>
                    <!-- /Close -->
                  </el-col>

                  <el-col :span="12">
                    <ib-button
                      class="w-100 text-uppercase"
                      size="lg"
                      :disabled="!isEnabled"
                      :loading="loading"
                      @click="saveTeamMember"
                    >
                      {{ form.id === 0 ? $t('add') : $t('save') }}
                    </ib-button>
                  </el-col>
                </el-row>
                <!-- /Discard & Add -->

              </div>
              <!-- /Dialog Bottom -->

            </el-col>
            <!-- /Left Side -->

            <!-- Center -->
            <el-col :md="12" :xl="7">

              <template v-if="!$store.state.idea.storyMode.setUp.managementTeam.members.length">
                <div class="d-flex flex-column justify-content-center align-items-center mt-5">
                  <img class="add-dialog-folder-icon" src="@/assets/img/icons/add-dialog-folder.svg" alt="">
                  <p class="add-dialog-folder-text font-outfit-regular">
                    {{ $t('createdItemsWillAppearHere') }}
                  </p>
                </div>
              </template>

              <!-- Members Added -->
              <div ref="cardHolder" class="members-added">

                <!-- Members Added List -->
                <ib-draggable
                  :list="$store.state.idea.storyMode.setUp.managementTeam.members"
                  @change="onChangeManagementTeamOrder"
                  @start="onStartManagementTeamOrder"
                >
                  <management-team-card
                    v-for="member in $store.state.idea.storyMode.setUp.managementTeam.members"
                    ref="managementTeamCard"
                    :key="member.id"
                    :member="member"
                    class="mb-2 draggable"
                    view="list"
                    :delete-disabled="form.id === member.id"
                    :edit-disabled="form.id === member.id"
                    @edit="onEditTeamMember"
                    @copy="onCopyTeamMember"
                    @delete="deleteStoryModeTeamMember($event.id)"
                  />
                </ib-draggable>
                <!-- /Members Added List -->
                <erase-icon-button
                  v-if="removeButtonVisible"
                  confirm
                  :loading="removeLoadingExamples"
                  @confirm="deleteExamples"
                />
              </div>
              <!-- /Members Added -->

            </el-col>
            <!-- /Center -->

            <!-- Right Side -->
            <el-col :xl="10">
              <!-- Info Guide -->
              <info-guide-add-dialog
                class="mb-5 d-none d-xl-block"
                :visible="guideVisible"
                :title="$t('pages.businessGuide.managementTeam.addDialog.guide.title')"
                :text="$t('pages.businessGuide.managementTeam.addDialog.guide.text')"
                @click="onToggleDialogGuide"
              />
              <!-- Info Guide -->

            </el-col>
            <!-- /Right Side -->
          </el-row>
        </div>
      </div>
      <!-- /Inner Wrapper -->

    </ib-dialog-container>

    <!-- /Content Wrapper -->

  </ib-dialog>
  <!-- /Dialog Add Management Team Member -->
</template>

<script>
import AddDialogHeader from '@/components/_v2/Common/AddDialogHeader'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import InfoGuideAddDialog from '@/views/Home/StoryMode/Components/Guide/InfoGuideAddDialog.vue'
import ManagementTeamCard from '@/views/Home/StoryMode/Components/Cards/ManagementTeamCard'
import MixinCloseMenu from '@/mixins/closeDropdownMenuOnScroll'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinDialog from '@/mixins/dialog'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import { getImageBySize } from '@/helpers/imageSizeHelper'

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DialogAddTeamMember',

  components: {
    InfoGuideAddDialog,
    EraseIconButton,
    AddDialogHeader,
    ManagementTeamCard
  },

  mixins: [
    MixinDialog,
    MixinGuide,
    MixinCloseMenu,
    MixinIdeaRoles,
    MixinDeleteIdeaExamples
  ],

  props: {
    dialogAddTeamMemberVisible: Boolean,
    memberAction: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      loading: false,
      removeLoadingExamples: false,
      guideVisible: false,
      form: {
        id: 0,
        ideaId: this.$store.state.idea.id,
        description: '',
        name: '',
        position: '',
        imageAvatarUrl: '',
        imageAvatarUrlPreview: ''
      }
    }
  },

  computed: {
    ...mapGetters('idea', ['getHasItemsFromTemplate']),

    isEnabled () {
      const namePopulated = this.form.name !== ''
      const positionPopulated = this.form.position !== ''

      return namePopulated && positionPopulated
    },

    isDialogOpen () {
      return this.dialogAddTeamMemberVisible
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getManagementTeam')
    }
  },

  methods: {
    ...mapActions('idea', [
      'deleteStoryModeTeamMember',
      'updateOrderNumber',
      'deleteIdeaExamples'
    ]),

    onStartManagementTeamOrder () {
      this.$refs.managementTeamCard.forEach(card => {
        card.$refs.actionsDropdown.$refs.itemDropdown.visible = false
      })
    },

    onChangeManagementTeamOrder (data) {
      this.updateOrderNumber({
        type: 'managementTeam',
        data,
        oldOrderData: this.managementTeam,
        mutation: 'setManagementTeam'
      })
    },

    closeDialog () {
      this.$emit('close-dialog-add-team-member')
    },

    closedDialog () {
      this.resetForm()
      this.guideVisible = false
    },

    openDialog () {
      this.openGuideDialog('addManagementTeamGuide')
      if (this.memberAction) {
        this.form.id = this.memberAction.id
        this.form.ideaId = this.$store.state.idea.id
        if (this.memberAction.image) {
          this.form.imageAvatarUrl = this.memberAction.image.filename
          this.form.imageAvatarUrlPreview = getImageBySize(this.memberAction.image.sizes, 'medium')
        } else {
          this.form.image = this.memberAction.image
        }
        this.form.name = this.memberAction.name
        this.form.position = this.memberAction.position
        this.form.description = this.memberAction.description
      }
    },

    onToggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    resetForm () {
      this.form = {
        id: 0,
        ideaId: this.$store.state.idea.id,
        imageAvatarUrl: null,
        name: '',
        position: '',
        description: ''
      }
      this.focus = {
        name: false,
        position: false
      }
    },

    resetGuide () {
      if (this.$store.getters['idea/getManagementTeam'].length === 0) {
        this.guideVisible = false
      }
    },

    saveTeamMember () {
      this.loading = true
      if (this.form.id === 0) {
        this.addTeamMember()
      } else {
        this.updateTeamMember()
      }
    },

    addTeamMember () {
      this.$http.post('story-mode/set-up/team/members', this.form)
        .then((response) => {
          if (response.status === 201) {
            this.resetGuide()
            this.$store.commit('idea/addTeamMember', response.data.payload.member)
            this.resetForm()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    updateTeamMember () {
      this.$http.put(`story-mode/set-up/team/members/${this.form.id}`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('idea/updateTeamMember', response.data.payload.member)
            this.resetForm()
          }
        }).finally(() => {
          this.loading = false
        })
    },

    onEditTeamMember (member) {
      this.resetForm()
      this.form = Object.assign({}, member)
      this.form.ideaId = this.$store.state.idea.id
      this.$set(this.form, 'imageAvatarUrlPreview', null)
      this.$set(this.form, 'imageAvatarUrl', null)
      if (member.image !== null) {
        this.form.imageAvatarUrl = member.image.filename
        this.form.imageAvatarUrlPreview = getImageBySize(member.image.sizes, 'medium')
      }
      this.scrollToTop()
    },

    onCopyTeamMember (member) {
      this.resetForm()
      this.form = Object.assign({}, member)
      this.form.id = 0
      this.form.ideaId = this.$store.state.idea.id
      this.$set(this.form, 'imageAvatarUrlPreview', null)
      this.$set(this.form, 'imageAvatarUrl', null)
      if (member.image !== null) {
        this.form.imageAvatarUrl = member.image.filename
        this.form.imageAvatarUrlPreview = getImageBySize(member.image.sizes, 'medium')
      }
      this.scrollToTop()
    },

    beforeAvatarUpload (file) {
      const isAcceptedFormat = file.type === 'image/jpeg' || file.type === 'image/png'
      const isImageSizeLowerThen5M = file.size / 1024 / 1024 <= 5

      if (!isAcceptedFormat) {
        // this.$message.error('Picture must be JPG or PNG format!')
        alert('Picture must be JPEG or PNG format!')
      }
      if (!isImageSizeLowerThen5M) {
        // this.$message.error('Picture size can not exceed 5MB!')
        alert('Picture size can not exceed 5MB!')
      }

      return isAcceptedFormat && isImageSizeLowerThen5M
    },

    handleAvatarSuccess (result, file) {
      this.form.imageAvatarUrl = result.payload.filename
      this.form.imageAvatarUrlPreview = URL.createObjectURL(file.raw)
    },

    deleteImage () {
      this.form.imageAvatarUrl = ''
      this.form.imageAvatarUrlPreview = ''
    },

    async deleteExamples () {
      try {
        this.removeLoadingExamples = true
        const response = await this.deleteIdeaExamples('management-team')
        const { type, ids } = response
        this.deleteFromState({ type, ids })
        this.resetForm()
        this.removeLoadingExamples = false
      } catch (error) {
        this.removeLoadingExamples = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

.add-dialog-folder-icon {
  width: 80px;
  @include media-breakpoint-up($xxl) {
    width: 100px;
  }
}

.add-dialog-folder-text {
  font-size: 14px;
  @include media-breakpoint-up($xxl) {
    font-size: 16px;
  }
}

</style>
